import dayjs, { Dayjs } from 'dayjs';
import { MAX_DAYS_IN_ADVANCE } from '@utils/constants';
import { LANG_LOCALE } from '@utils/types';

export const LOCALIZED_MONTH_AND_YEAR_FORMAT: {
  [key in LANG_LOCALE]: string;
} = {
  en: 'MMMM, YYYY',
  ja: 'YYYY年MMMM',
  ko: 'YYYY년 MMMM',
  'zh-tw': 'YYYY年MMM',
};

export const isCheckInAndCheckOutValid = (checkin: Dayjs, checkout: Dayjs) => {
  if (!dayjs.isDayjs(checkin) || !dayjs.isDayjs(checkout)) {
    return false;
  }

  const currentDate = dayjs();
  const availabilityLimitDate = dayjs().add(MAX_DAYS_IN_ADVANCE, 'day');

  const isCheckInValid =
    (checkin.isSame(currentDate) || checkin.isAfter(currentDate)) &&
    checkin.isBefore(availabilityLimitDate);
  const isCheckOutValid =
    checkout.isAfter(currentDate) &&
    checkout.isAfter(checkin) &&
    (checkout.isSame(availabilityLimitDate) ||
      checkout.isBefore(availabilityLimitDate));

  return isCheckInValid && isCheckOutValid;
};

// Calculates the difference in hours and remainder minutes between two dates.
export const diffHoursAndRemainderMinutes = (
  startDate: string,
  endDate: string
) => {
  const hour = dayjs(startDate).diff(endDate, 'hour');
  const minutes = dayjs(startDate).diff(endDate, 'minute');
  const remainderMinutes = minutes % 60;

  return { hour, remainderMinutes };
};
